export default function TIcon() {
  return (
    <svg
      width="263"
      height="334"
      viewBox="0 0 263 334"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 67.75V0.249996H262.4V67.75H167.9V333.25H95V67.75H0.5Z"
        fill="black"
      />
    </svg>
  );
}
