import styled from "styled-components";

export const FaqWrapper = styled.div`
  background: ${(props) => props.theme.colors.purple_500};
  padding: 10rem 0rem;
  .accordion-button::after {
    background-size: 10rem;
  }
  .accordion {
    --bs-accordion-bg: transparent;
    --bs-accordion-border-color: none;
  }
  accordion-item:first-of-type > .accordion-header .accordion-button {
    border: none;
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: transparent;
    box-shadow: none;
  }
  .accordian_wrapper {
    display: flex;
    align-items: center;
  }
  .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
  }
  .border-bottom {
    border-bottom: 2px solid white !important;
    padding-bottom: 20px;
  }
`;

export const RotatingIconWrapper = styled.div<{ rotated: boolean }>`
  display: inline-block;
  transition: transform 0.3s ease;
  transform: ${({ rotated }) => (rotated ? "rotate(-180deg)" : "rotate(0deg)")};
  margin-right: 10px;
`;
