import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import { Piecerapper } from "./styles";
import Paragraph from "views/components/shared/Paragraph";
import { sectionsData } from "./utils/data";
import AOS from "aos";
import "aos/dist/aos.css";

function Piece() {
  useEffect(() => {
    AOS.init({
      duration: 3000,
      offset: 0,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  return (
		<Piecerapper>
			<Container>
				<Row className="justify-content-between">
					<Col md={5}>
						<div data-aos="zoom-in-up" data-aos-duration="1000" className="content-box text-md-start text-center mb-5 mb-md-0">
							<Heading className="h2medium" level={2}>
								We’re committed to joining your<span>&nbsp;vision</span>.
							</Heading>
						</div>
					</Col>
					<Col md={6}>
						{sectionsData.map((section, index) => (
							<div className="mb-5" key={section.id}>
								<div
									className="content_wrapper"
									data-aos="fade-in"
									data-aos-duration="1000"
									data-aos-delay={index * 900}
								>
									<div
										className="piece_icon"
										data-aos="zoom-in"
										data-aos-duration="900"
										data-aos-delay={index * 1000}
									>
										{section.icon}
									</div>
									<div>
										<Paragraph
											className="mdl text-white m-0"
											data-aos="fade-in"
											data-aos-duration="300"
											data-aos-delay={index * 1200}
										>
											{section.paragraph}
										</Paragraph>
									</div>
								</div>
							</div>
						))}
					</Col>
				</Row>
			</Container>
		</Piecerapper>
	);
}

export default Piece;
