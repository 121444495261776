import styled from "styled-components";

export const PlayWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white_200};
  padding: 0 0 3rem 0;
  .h4Medium {
    padding: 0;
    span {
      color: ${(props) => props.theme.colors.aqua_100};
    }
  }
`;
