import styled from "styled-components";

export const CookieWrapper = styled.header`
  font-family: "SatoshiRegular";
  backdrop-filter: blur(18px);
  background-color: rgba(255, 246, 249, 0.78);
  border-top: 1px solid rgba(67, 83, 255, 0.16);
  padding: 1.5rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 20px;
  box-sizing: border-box;
  margin: 16px auto;
  position: fixed;
  left: 16px;
  right: 16px;
  bottom: 16px;
  max-width: 600px;
  z-index: 1;
  left: 50%;
  @media (max-width: 780px) {
    left: 2%;
  }
  .accept-cookie {
    color: #fff;
    background: #4a93cf;
    padding: 0.35rem 0.9rem;
    font-size: 0.9rem;
    font-weight: 700;
    border: 1px solid #4a93cf;
    border-radius: 50px;
    height: fit-content;
    transition: all 0.5s;
  }
  .reject-cookie {
    color: #000;
    background: transparent;
    padding: 0.35rem 0.9rem;
    font-size: 0.9rem;
    font-weight: 700;
    border: 1px solid #000;
    border-radius: 50px;
    height: fit-content;
    transition: all 0.5s;
    margin-right: 10px;
  }
`;
