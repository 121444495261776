import styled from "styled-components";

export const FundWrapper = styled.div`
  position: relative;
  padding: 8rem 0;
  background-color: ${(props) => props.theme.colors.white_100};
  overflow: hidden;
  height: 170vh;

  .hidden {
    opacity: 0;
  }
  @media screen and (max-width: 780px) {
    position: relative;
    padding: 5rem 0;
    background-color: #fff;
    overflow: hidden;
    height: 55vh;
  }
`;

export const Circle = styled.div`
  position: absolute;
  bottom: 0;
  right: -25%;
  background-color: ${(props) => props.theme.colors.purple_500};
  border-radius: 50%;
  z-index: 1;
  width: 0; /* Initial width */
  height: 0; /* Initial height */
`;

export const NewText = styled.div`
  opacity: 1;
  transition: opacity 0.5s linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  z-index: 2;
`;
