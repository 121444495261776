import { Card, Col, Row, Spinner } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";
import { Link } from "react-router-dom";
import { NewsCardWrapper } from "./styles";
import { useEffect, useState } from "react";
import { fetchEntries } from "contentFullApi";
import defaultImg from "assets/Images/testimonial_img1.jpg";
import { Post } from "../utils/types";

const NewsCard = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getPosts = async () => {
      try {
        const entries = await fetchEntries("taskgoNews");
        setPosts(entries);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setLoading(false);
      }
    };

    getPosts();
  }, []);

  const getShortDescription = (
    description: Post["fields"]["description"]
  ): string => {
    let fullText = "";
    description.content.forEach((contentBlock) => {
      contentBlock.content.forEach((textBlock) => {
        fullText += textBlock.value;
      });
    });
    return fullText.substring(0, 100) + "...";
  };

  return (
    <NewsCardWrapper>
      <Row>
        {posts.map((post) => {
          const { title, description, featureImageUrl, slug, tag } =
            post.fields;
          const imageUrl = featureImageUrl || defaultImg;

          return (
            <>
              {loading ? (
                <div className="spiner-wrapper">
                  <Spinner animation="grow" />
                </div>
              ) : (
                <Col md={4} key={post.sys.id} className="mt-4">
                  <Link to={`/news/${slug}`}>
                    <Card className="card-wrapper">
                      <Card.Img
                        src={imageUrl}
                        className="img-fluid"
                        style={{ height: "240px", objectFit: "cover" }}
                      />
                      <Card.Body>
                        <div className="tag">{tag}</div>
                        <Heading level={2} className="privacy mt-3">
                          {title}
                        </Heading>
                        <Paragraph className="mdxl">
                          {getShortDescription(description)}
                        </Paragraph>
                        <Link to={`/news/${slug}`} className="link">
                          Read More
                        </Link>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )}
            </>
          );
        })}
      </Row>
    </NewsCardWrapper>
  );
};

export default NewsCard;
