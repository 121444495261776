import React from "react";
const FullCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 15 15"
      className="circle_icon"
    >
      <path
        fill="#ffffff"
        d="M4.854 6.146L4.5 5.793l-.707.707l.353.354zM7.5 9.5l-.354.354l.354.353l.354-.353zm3.354-2.646l.353-.354l-.707-.707l-.354.353zm-6.708 0l3 3l.708-.708l-3-3zm3.708 3l3-3l-.708-.708l-3 3zM14 7.5A6.5 6.5 0 0 1 7.5 14v1A7.5 7.5 0 0 0 15 7.5zM7.5 1A6.5 6.5 0 0 1 14 7.5h1A7.5 7.5 0 0 0 7.5 0zM1 7.5A6.5 6.5 0 0 1 7.5 1V0A7.5 7.5 0 0 0 0 7.5zm-1 0A7.5 7.5 0 0 0 7.5 15v-1A6.5 6.5 0 0 1 1 7.5z"
      />
    </svg>
  );
};
export default FullCircleIcon;
