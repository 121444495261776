import { Container } from "react-bootstrap";
import NewsCard from "views/components/pages/News/NewsCard";

const News = () => {
  return (
    <Container>
      <NewsCard />
    </Container>
  );
};

export default News;
