import styled from "styled-components";

export const GrothWrapper = styled.div`
  padding: 8rem 0;
  @media screen and (max-width: 780px) {
    padding: 3rem 0;
  }
  overflow: hidden;
  .h2SmMedium {
    span {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }
  .wave-animation-wraper {
    position: relative;
    margin-top: 3rem;
  }
  .wave-animation-position {
    position: absolute;
    right: 0;
    bottom: -500px;
    z-index: 1;
    @media (max-width: 780px) {
      bottom: -290px;
    }
  }
  .bg-icon-t {
    z-index: 0;
    width: 330px;
    height: 430px;
    opacity: 0.2;
    mix-blend-mode: multiply;
    position: absolute;
    top: -120px;
    left: 0;
    will-change: transform !important;
    transform: translate3d(-25.116%, -23.116%, 0px) scale3d(1, 1, 1)
      rotateX(57deg) rotateY(5deg) rotateZ(7deg) skew(-23deg, 38deg) !important;
  }

  .bg-icon-b {
    z-index: 0;
    width: 270px;
    height: 320px;
    opacity: 0.06;
    mix-blend-mode: multiply;
    position: absolute;
    top: 252px;
    left: auto;
    right: 48%;
    will-change: transform;
    transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  .bg-shape-semi-circle {
    z-index: 0;
    width: 110px;
    height: 120px;
    opacity: 0.18;
    mix-blend-mode: multiply;
    position: absolute;
    bottom: -30px;
    left: auto;
    right: 53%;
    @media (max-width: 780px) {
      right: 0;
    }
  }
`;
