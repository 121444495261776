import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { HeroButtonWrapper } from "./styles";
import HeroButtonProps from "./utils/interfaces";

const CustomButton: React.FC<HeroButtonProps> = ({
  children,
  className,
  onClick,
  style,
  to,
}) => {
  return (
    <HeroButtonWrapper>
      <Link to={to} style={{ textDecoration: "none" }}>
        <Button className={className} onClick={onClick} style={style}>
          {children}
        </Button>
      </Link>
    </HeroButtonWrapper>
  );
};

export default CustomButton;
