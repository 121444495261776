import styled from "styled-components";

export const FounderWrapper = styled.div`
  background: ${(props) => props.theme.colors.white_200};
  padding: 10rem 0rem;
  @media screen and (max-width: 992px) {
    padding: 5rem;
  }
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`;
