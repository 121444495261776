import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";
import { PlayWrapper } from "./styles";
import playImage from "assets/Images/about_where_we_play.jpg";

export default function Play() {
  return (
    <PlayWrapper>
      <Container>
        <Row>
          <Col lg={8}>
            <Heading className="h4Medium" level={2}>
              Where we play<span>.</span>
            </Heading>
            <Paragraph className="lgSmall">
              We offer a truly diverse service offering. Many claim they know
              pharmacy, and many claim they can also boost your profits. Taskgo
              actually does. We won’t waste your time. We boost profits through
              holistically looking at your business, focusing on your website,
              and by saving you time using our pharmacy management system.
            </Paragraph>
          </Col>
          <Row>
            <Col lg={8}>
              <div className="div_wrapper text-center mt-4">
                {/* <PlayIcon /> */}
                <Image src={playImage} fluid />
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </PlayWrapper>
  );
}
