import styled from "styled-components";

export const ChatWrapper = styled.div`
  padding: 12rem 0;
  .chat_link {
    color: ${(props) => props.theme.colors.purple_100};
    font-family: "SatoshiBold";
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
  }
`;
