import { Card, Col, Container, Row } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";
import ContactForm from "../Form";
import { ContactHeroWrapper } from "./styles";

const ContactHero = () => {
  return (
    <ContactHeroWrapper>
      <Container>
        <Row>
          <Col md={6}>
            <Heading
              className="h1 text-white ps-0 mt-5 pt-5 pt-md-0 mt-md-0"
              level={2}
            >
              Contact Us
            </Heading>
            <Paragraph className="mdl text-white">
              Email: info@taskgo.ie Or fill in the form:
            </Paragraph>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body className="ps-4 pe-4 pt-3 pb-3">
                <ContactForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ContactHeroWrapper>
  );
};

export default ContactHero;
