import AboutImg from "assets/Images/About-img.jpg";
import { AppRoutes } from "views/components/constants/routes";

export const aboutData = [
  {
    id: 1,
    imageSrc: AboutImg,
    overlayText:
      "Every business consultant tells you they offer valuable support to their clients",
    heading1: "How many clients value that support?",
    heading2: "Ours do.",
    buttonText: "Find out how",
    buttonLink: AppRoutes.contact,
  },
];
