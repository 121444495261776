import TestimonialImg from "assets/Images/testimonial_img1.jpg";
import TestimonialImg2 from "assets/Images/testimonial_img2.jpg";
import TestimonialImg3 from "assets/Images/testimonial_img3.jpg";

export const cardData = [
  {
    id: 1,
    text: "We’ve helped grow pharmacy profits to over 650,000 Euros through our hands on approach to achieving business success. We help across all areas of the business from developing a strategy right through to execution.",
    imgSrc: TestimonialImg,
    title: "Business Consultancy ",
  },
  {
    id: 2,
    text: "We take a UX focused approach to building pharmacy websites. This ensures that your pharmacy website is more than just an information page but is designed to maximise user friendliness and profit.",
    imgSrc: TestimonialImg2,
    title: "Digital Solutions",
  },
  {
    id: 3,
    text: "We’re Ireland’s first total pharmacy management system. We aim to streamline pharmacy management through our unique software that removes administrative workload and saves you hundreds of hours. Our aim is to make pharmacy paperless through software innovation",
    imgSrc: TestimonialImg3,
    title: "Taskgo Pharmacy Management System (PMS)",
  },
];
