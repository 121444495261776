import axios from "axios";

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const BASE_URL = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/master`;

const fetchAsset = async (assetId) => {
  try {
    const response = await axios.get(`${BASE_URL}/assets/${assetId}`, {
      params: {
        access_token: ACCESS_TOKEN,
      },
    });
    return response.data.fields.file.url;
  } catch (error) {
    console.error("Error fetching asset from Contentful:", error);
    throw error;
  }
};

export const fetchEntries = async (contentType) => {
  try {
    const response = await axios.get(`${BASE_URL}/entries`, {
      params: {
        access_token: ACCESS_TOKEN,
        content_type: contentType,
      },
    });

    const items = response.data.items;

    const itemsWithAssets = await Promise.all(
      items.map(async (item) => {
        if (item.fields.featureImage) {
          const assetId = item.fields.featureImage.sys.id;
          const imageUrl = await fetchAsset(assetId);
          item.fields.featureImageUrl = `https:${imageUrl}`;
        }
        return item;
      })
    );

    return itemsWithAssets;
  } catch (error) {
    console.error("Error fetching entries from Contentful:", error);
    throw error;
  }
};
