import styled from "styled-components";

export const HeadingWrapper = styled.div`
  .h1 {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    font-size: 5.8rem;
    font-weight: 700;
    line-height: 1.2;
    padding: 0rem 0rem 0rem 3rem;
    @media screen and (max-width: 992px) {
      font-size: 3.813rem;
      padding: 4rem;
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      font-size: 2.813rem;
      padding: 1rem;
      width: 100%;
    }
  }
  .h2xl {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 5.625rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 992px) {
      font-size: 3.813rem;
      margin-top: 1rem;
    }
  }

  .h2l {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    font-size: 4rem;
    margin: auto;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 2rem;
    @media screen and (max-width: 992px) {
      text-align: center;
      width: 100%;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .h2XMedium {
    color: ${(props) => props.theme.colors.purple_100};
    font-family: "SatoshiBold";
    font-size: 3rem;
    margin: auto;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    .aqua {
      color: ${(props) => props.theme.colors.aqua_100};
    }
    @media screen and (max-width: 992px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .h2medium {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 3.8rem;
    margin: auto;
    font-weight: 700;
    line-height: 1.2;
    span {
      color: ${(props) => props.theme.colors.aqua_100};
    }
    @media screen and (max-width: 992px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .h2SmMedium {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    max-width: 25ch;
    margin: auto;
    font-size: 5vw;
    font-weight: 700;
    line-height: 1.2;
    span {
      color: ${(props) => props.theme.colors.aqua_100};
    }
    @media screen and (max-width: 992px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .h2small {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiRegular";
    font-size: 2.7rem;
    font-weight: 400;
    line-height: 1.2;
    width: 65%;
    margin: auto;
    @media screen and (max-width: 992px) {
      padding: 1rem;
      font-size: 1.7rem;
      width: 100%;
      text-align: center;
    }
  }

  .h2Xsm {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: start;
  }

  .h3medium {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: start;
    padding: 11rem 6rem;
    @media screen and (max-width: 992px) {
      padding: 3rem;
    }
    @media screen and (max-width: 600px) {
      padding: 1rem;
    }
  }
  h4 {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 6.5rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }
  .h4Medium {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    padding: 0rem 5rem;
    @media screen and (max-width: 992px) {
      padding: 3rem 0rem;
    }
  }
  .h4Small {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-align: start;
  }
  h5 {
    color: ${(props) => props.theme.colors.purple_0};
    font-family: "SatoshiBold";
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }
  .h5XXl {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    span {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }
  .privacy {
    font-family: "SatoshiBold";
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-align: start;
  }
  .detail-news-title {
    font-family: "SatoshiBold";
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    text-align: start;
  }
`;
