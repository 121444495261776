import HeroImg from "assets/Images/hero_img1.jpg";
import HeroImg2 from "assets/Images/hero_img2.jpg";
import HeroImg3 from "assets/Images/hero_img3.jpg";

const heroData = [
  {
    id: 1,
    src: HeroImg,
    alt: "First slide",
  },
  {
    id: 2,
    src: HeroImg2,
    alt: "Second slide",
  },
  {
    id: 3,
    src: HeroImg3,
    alt: "Third slide",
  },
  {
    id: 4,
    src: HeroImg,
    alt: "Fourth slide",
  },
];

export default heroData;
