import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GrothWrapper } from "./styles";
import Paragraph from "views/components/shared/Paragraph";
import Heading from "views/components/shared/Heading";
import AOS from "aos";
import "aos/dist/aos.css";
import Lottie from "lottie-react";
import animationData from "assets/Animations/waveAnimation.json";
import SemiCircle from "assets/Icons/semiCircle";
import TIcon from "assets/Icons/TIcon";
import GIcon from "assets/Icons/GIcon";

const Growth = () => {
  const [hasLoadedAnimation, setHasLoadedAnimation] = useState(false);
  const animationRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 0,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasLoadedAnimation) {
          setHasLoadedAnimation(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (animationRef.current) {
      observer.observe(animationRef.current);
    }

    return () => {
      if (animationRef.current) {
        observer.unobserve(animationRef.current);
      }
    };
  }, [hasLoadedAnimation]);

  return (
    <GrothWrapper>
      <Container fluid className="px-0">
        <Row className="my-4 justify-content-between align-items-center">
          <Col md={12} style={{ position: "relative" }}>
            <div className="bg-icon-t">
              <TIcon />
            </div>
            <div className="bg-icon-b">
              <GIcon />
            </div>
            <div className="bg-shape-semi-circle">
              <SemiCircle />
            </div>
          </Col>
          <Col
            md={{ span: 5, offset: 1 }}
            data-aos="fade-up"
            data-aos-duration="1200"
            className="pl-4"
          >
            <Heading level={2} className="h2SmMedium">
              Ready. Set. Grow! <span>.</span>
            </Heading>
            <Paragraph className="mdl mt-3">
              Your hunger for success brings out the best in us. And we’re there
              with you at the most critical times for your business.
            </Paragraph>
            <Paragraph className="text-muted mdl">
              Taskgo doesn’t just hold discussions. We fill you with knowledge,
              experience and confidence to take action and grow.
            </Paragraph>
          </Col>
          <Col md={6} className="mt-5 mb-5">
            <div className="wave-animation-wraper" ref={animationRef}>
              {hasLoadedAnimation && (
                <div className="wave-animation-position">
                  <Lottie animationData={animationData} loop={false} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </GrothWrapper>
  );
};

export default Growth;
