import ArrowIcon from "assets/Icons/ArrowIcon";
import React, { useRef, useState } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { ContactFormWrapper } from "./styles";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [requestComplete, setrequestComplete] = useState(false);
  const [requestFail, setrequestFail] = useState(false);

  const initialFormData = {
    name: "",
    pharmacy: "",
    position: "",
    contact: "",
    email: "",
    services: "",
    optIn: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const emailData = {
      ...formData,
      optIn: formData.optIn ? "Yes" : "No",
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        emailData,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setLoading(false);
          setrequestComplete(true);
          setFormData(initialFormData);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setLoading(false);
          setrequestFail(true);
        }
      );
  };

  return (
    <ContactFormWrapper>
      <Form ref={form} onSubmit={sendEmail}>
        <Row>
          <Col md={6} className="mt-3">
            <Form.Group controlId="formName">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-3">
            <Form.Group controlId="formPharmacy">
              <Form.Label>Pharmacy:</Form.Label>
              <Form.Control
                type="text"
                name="pharmacy"
                value={formData.pharmacy}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-3">
            <Form.Group controlId="formPosition">
              <Form.Label>Position:</Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={formData.position}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-3">
            <Form.Group controlId="formContactNumber">
              <Form.Label>Contact Number:</Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-3">
            <Form.Group controlId="formEmailAddress">
              <Form.Label>Email Address:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-3">
            <Form.Group controlId="formInterestedService">
              <Form.Label>Interested Service:</Form.Label>
              <Form.Control
                as="select"
                name="services"
                value={formData.services}
                onChange={handleChange}
                required
              >
                <option value="">Select a service</option>
                <option value="Business Consultancy">
                  Business Consultancy
                </option>
                <option value="Web design">Web design</option>
                <option value="Pharmacy Management System">
                  Pharmacy Management System
                </option>
                <option value="all the above">All the above</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formOptIn" className="mt-4">
          <Form.Check
            type="checkbox"
            label="Opt in for informative emails"
            name="optIn"
            checked={formData.optIn}
            onChange={handleChange}
          />
        </Form.Group>
        <Button type="submit" className="secondary mt-5">
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <>
              Submit
              <span className="arrow-icon">
                <ArrowIcon />
              </span>
            </>
          )}
        </Button>
        {requestComplete && (
          <Alert key={"success"} variant={"success"} className="mt-3">
            Message sent successfully. We’ll get back to you soon
          </Alert>
        )}
        {requestFail && (
          <Alert key={"danger"} variant={"danger"} className="mt-3">
            Something went wrong
          </Alert>
        )}
      </Form>
    </ContactFormWrapper>
  );
};

export default ContactForm;
