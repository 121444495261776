import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "views/components/shared/Button";
import { AboutWrapper } from "./styles";
import Heading from "views/components/shared/Heading";
import ArrowIcon from "assets/Icons/ArrowIcon";
import { aboutData } from "./utils/data";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Default duration, can be overridden by data attributes
      offset: 0,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  return (
    <AboutWrapper>
      <Container fluid>
        {aboutData.map((item, index) => (
          <Row key={item.id}>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center order-2 order-md-1 p-0"
            >
              <div className="text-center image_wrapper">
                <Image
                  src={item.imageSrc}
                  alt="About Image"
                  className="img-fluid image-wrapper"
                  width={1520}
                />
                <div className="overlay">
                  <div
                    data-aos="fade-up"
                    data-aos-duration={`${2000 + index * 300}`}
                    data-aos-once={true}
                  >
                    <Heading level={4} className="h3medium">
                      {item.overlayText}
                      <span>.</span>
                    </Heading>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="div_col_sec order-1 order-md-2 p-0">
              <div
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={1000}
                data-aos-once={true}
                className="text-center"
              >
                <Heading level={2} className="h2small text-center">
                  {item.heading1}
                </Heading>
              </div>
              <div
                data-aos="zoom-in"
                data-aos-duration={1000}
                data-aos-delay={2000}
                data-aos-once={true}
                className="text-center"
              >
                <Heading level={2} className="h2xl">
                  {item.heading2}
                </Heading>
                <Button to={item.buttonLink} className="secondary mt-5">
                  {item.buttonText}{" "}
                  <span className="arrow-icon">
                    <ArrowIcon />
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </AboutWrapper>
  );
};

export default AboutSection;
