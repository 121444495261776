import React, { useEffect, useState } from "react";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import { HeroWrapper } from "./styles";
import heroData from "./utils/data";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroAnimation from "views/components/shared/HeroAnimation";
import Lottie from "lottie-react";
import animationData from "assets/Animations/arrowAnimation.json";

function Hero() {
  const [isAnimationVisible, setIsAnimationVisible] = useState(true);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 0,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldAnimateOut(true);
      const hideTimer = setTimeout(() => {
        setIsAnimationVisible(false);
        setIsTextVisible(true);
      }, 1000);
      return () => clearTimeout(hideTimer);
    }, 3600);
    return () => clearTimeout(timer);
  }, []);

  return (
    <HeroWrapper className="hero-section">
      <Container fluid>
        {isAnimationVisible && (
          <Row
            className={`logo-bg ${shouldAnimateOut ? "slide-out-right" : ""}`}
          >
            <Col md={12}>
              <div
                className={`mobile-animation ${
                  shouldAnimateOut ? "slide-out-right-with-fade" : ""
                }`}
              >
                <HeroAnimation />
              </div>
            </Col>
          </Row>
        )}
        <Row className="hero-wrapper">
          <Col md={6} className="responsive-img-wrapper">
            {isTextVisible && (
              <div className="arrow-animation">
                <Lottie animationData={animationData} loop={true} />
              </div>
            )}
            {isTextVisible && (
              <div
                className="hero-text"
                data-aos="fade-right"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <Heading level={1} className="h1">
                  Supporting Pharmacies to make more profit<span>.</span>
                </Heading>
              </div>
            )}
          </Col>
          <Col md={6} className="p-0">
            <Carousel controls={false} interval={3000} wrap={false}>
              {heroData.map((slide) => (
                <Carousel.Item key={slide.id}>
                  <Image
                    className="d-block w-100 slider-img"
                    src={slide.src}
                    alt={slide.alt}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
}

export default Hero;
