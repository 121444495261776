import AboutUs from "views/components/pages/About/AboutUs";
import Chat from "views/components/pages/About/Chat";
import Companies from "views/components/pages/About/Companies";
import Faq from "views/components/pages/About/Faq";
import Founder from "views/components/pages/About/Founder";
import AboutHero from "views/components/pages/About/Hero";
import Play from "views/components/pages/About/Play";
import Process from "views/components/pages/About/Process";

const About = () => {
  return (
    <>
      <AboutHero />
      <AboutUs />
      <Founder />
      {/* <Companies /> */}
      <Play />
      <Process />
      <Faq />
      <Chat />
    </>
  );
};

export default About;
