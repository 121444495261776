import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";
import { ProcessWrapper } from "./styles";
import { data } from "./utils/data";

const Process = () => {
  return (
    <ProcessWrapper>
      <Container>
        <Row className="justify-content-between">
          <Heading className="h5XXl pb-5" level={2}>
            Our process<span>.</span>
          </Heading>
          {data.map((step, index) => (
            <Col lg={3} key={index}>
              <div className="process_wrapper">
                <Paragraph className="mdLarge">{step.number}</Paragraph>
                <div>
                  <Heading className="h2Xsm" level={3}>
                    {step.title}
                  </Heading>
                  <Paragraph className="mdSmall">{step.description}</Paragraph>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </ProcessWrapper>
  );
};

export default Process;
