import { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import { FundWrapper, Circle, NewText } from "./styles";
import { throttle } from "lodash";
import AOS from "aos";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const Funding = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const [circleLoaded, setCircleLoaded] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 0,
      easing: "ease-in-out",
    });
    AOS.refresh();

    gsap.registerPlugin(ScrollTrigger);
    gsap.set(".dot", { xPercent: -50, yPercent: -50 });

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".block",
        start: "top center",
        end: "+=100%",
        scrub: 0.5,
        pin: ".dot",
      },
    });

    tl.from(".dot", {
      scale: 0.1,
      repeat: 0,
      yoyo: true,
    });

    const handleScroll = () => {
      if (containerRef.current && circleRef.current) {
        const { top, height } = containerRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY + window.innerHeight;
        const containerPosition = top + window.scrollY;
        const scrollThreshold = height * 0.8;

        const maxCircleSize = Math.min(
          130,
          ((scrollPosition - containerPosition) / height) * 130
        );

        gsap.to(circleRef.current, {
          width: `${maxCircleSize}vw`,
          height: `${maxCircleSize}vw`,
          borderRadius: maxCircleSize >= 130 ? 0 : "50%",
          duration: 0.3,
          ease: "power1.out",
        });

        const newCircleLoaded =
          scrollPosition >= containerPosition + scrollThreshold;
        if (circleLoaded !== newCircleLoaded) {
          setCircleLoaded(newCircleLoaded);
        }
      }
    };

    const handleScrollThrottled = throttle(handleScroll, 50);

    window.addEventListener("scroll", handleScrollThrottled);
    return () => {
      window.removeEventListener("scroll", handleScrollThrottled);
    };
  }, [circleLoaded]);

  return (
    <FundWrapper ref={containerRef}>
      <Container style={{ position: "relative", zIndex: 1 }}>
        <Row className="justify-content-center">
          <Col
            md={8}
            className="md-8"
            data-aos="fade-up"
            data-os-duration="500"
          >
            <Heading
              level={2}
              className={`h2SmMedium text-center ${
                circleLoaded ? "hidden" : ""
              }`}
              style={{
                zIndex: circleLoaded ? -1 : 0,
                opacity: circleLoaded ? 0 : 1,
                transition: "opacity 0.5s linear",
              }}
            >
              This is support that’s more than just words <span>.</span>
            </Heading>
          </Col>
        </Row>
      </Container>
      <NewText
        style={{
          opacity: circleLoaded ? 1 : 0,
          transition: "opacity 0.5s linear",
        }}
      >
        <Container style={{ position: "relative", zIndex: 2 }}>
          <Row>
            <Col className="md-8" data-aos="fade-up">
              <Heading level={5} className="text-center text-white">
                This is
              </Heading>
              <Heading level={4} className="text-center">
                Taskgo
              </Heading>
            </Col>
          </Row>
        </Container>
      </NewText>
      <div className="block block--1">
        <div className="dot"></div>
      </div>
      <div className="block block--2"></div>
      <Circle ref={circleRef} />
    </FundWrapper>
  );
};

export default Funding;
