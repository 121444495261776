export default function GIcon() {
  return (
    <svg
      width="319"
      height="344"
      viewBox="0 0 319 344"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.5 68.9996C108.2 68.9996 77.15 111.3 77.15 175.65C77.15 242.7 114.95 276 168.05 276C222.95 276 247.25 246.3 250.4 211.65H162.2V147.75H318.8V339H256.25L252.2 308.85C234.2 330 198.2 343.95 158.6 343.95C65.45 343.95 0.199951 275.55 0.199951 173.4C0.199951 73.0496 67.25 0.599609 170.3 0.599609C249.95 0.599609 311.15 47.3996 318.8 119.4H242.3C233.75 84.2996 203.15 68.9996 168.5 68.9996Z"
        fill="black"
      />
    </svg>
  );
}
