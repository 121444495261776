import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FooterWrapper } from "./styles";
import LinkedInIcon from "../../../../assets/Icons/LinkedInIcon";
import { Link } from "react-router-dom";
import Paragraph from "../Paragraph";
import { AppRoutes } from "views/components/constants/routes";

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Row className="justify-content-between">
          <Col md={4}>
            <h2 className="footer_logo">Taskgo.</h2>
          </Col>
          <Col className=" mb-4" md={1}>
            <Link to={AppRoutes.about} className="footer_link">
              About
            </Link>
          </Col>
          <Col md={1}>
            <Link to={AppRoutes.contact} className="footer_link mb-4">
              Contact
            </Link>
          </Col>
          <Col md={4}>
            <Link
              className="footer_icon "
              to="https://www.linkedin.com/company/taskgo-pharma/"
              target="_blank"
            >
              <LinkedInIcon />
            </Link>
          </Col>
        </Row>
        <Row>
          <hr />
          <div className="copyright-holder">
            <Paragraph className="mdMedium">
              © 2024 Taskgo. All right reserved.
            </Paragraph>
            <Link className="mr-3" to={AppRoutes.privacy}>
              Privacy Policy
            </Link>
            <Link to={AppRoutes.cookies}>Cookies Settings</Link>
          </div>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
