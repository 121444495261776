import styled from "styled-components";

export const AboutWrapper = styled.div`
  overflow: hidden;
  .image_wrapper {
    position: relative;
    .overlay {
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.66),
        rgba(0, 0, 0, 0.66)
      );
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      height: 100vh;
      display: flex;
      align-items: center;
      @media (max-width: 780px) {
        height: 70vh;
      }
    }
  }
  .h3medium {
    span {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }
  .div_col_sec {
    padding: 3rem 0rem !important;
    background: ${(props) => props.theme.colors.purple_100};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;
    }
  }
  .image-wrapper {
    height: 100vh !important;
    object-fit: cover;
    @media (max-width: 780px) {
      height: 70vh !important;
    }
  }
`;
