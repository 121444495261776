import styled from "styled-components";

export const ContactFormWrapper = styled.div`
  .form-control {
    height: 50px;
  }
  .form-label {
    font-family: "SatoshiRegular";
  }
  .secondary {
    font-family: "SatoshiBold";
    color: ${(props) => props.theme.colors.white_100};
    background: ${(props) => props.theme.colors.purple_200};
    padding: 0.45rem 1.8rem;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    display: flex;
    align-items: center;
    grid-column-gap: 0.5rem;
    border-radius: 50px;
    height: fit-content;
    transition: all 0.5s;
    display: flex;
    margin: auto;
    @media (max-width: 768px) {
      margin: auto;
    }

    &:hover {
      box-shadow: 0px 4px 8px 0px #00000026;
      background-color: ${(props) => props.theme.colors.purple_400};
    }

    .arrow-icon {
      display: inline-block;
      transition: transform 0.5s ease;
    }

    &:hover .arrow-icon {
      transform: translateX(5px); /* Adjust the value as needed */
    }
  }
`;
