import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { AppRoutes } from "views/components/constants/routes";
import { CookieWrapper } from "./styles";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [isVisible, setIsVisible] = useState(true);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
    setIsVisible(false);
  };

  const rejectCookieConsent = () => {
    setCookie("cookieConsent", false, { path: "/" });
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <CookieWrapper>
      <div className="cookie-consent">
        <p>
          By clicking <span className="fw-bold">“Accept All Cookies”</span>, you
          agree to the storing of cookies on your device to enhance site
          navigation, analyze site usage, and assist in our marketing efforts.
          View our&nbsp;
          <Link to={AppRoutes.privacy}>Privacy Policy</Link> for more
          information.
        </p>

        <Button className="reject-cookie" onClick={rejectCookieConsent}>
          Reject
        </Button>

        <Button className="accept-cookie" onClick={giveCookieConsent}>
          Accept
        </Button>
      </div>
    </CookieWrapper>
  );
};

export default CookieConsent;
