import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.colors.purple_200};
  padding: 5rem;
  color: #343a40;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  .footer_logo {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiRegular";
    font-size: 2.2rem;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  .footer_link {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiRegular";
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
  }
  .footer_icon {
    cursor: pointer;
    display: flex;
    justify-content: end;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 1.5rem;
    }
  }
  hr {
    color: ${(props) => props.theme.colors.white_100};
    border: 0;
    margin-top: 5rem;
    border-top: 2px solid;
    opacity: 1;
  }
  .copyright-holder {
    grid-column-gap: 1.5rem;
    grid-row-gap: 0rem;
    white-space: normal;
    grid-template-rows: auto;
    grid-template-columns: max-content;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    justify-content: center;
    display: grid;
    @media screen and (max-width: 768px) {
      grid-column-gap: 0rem;
      grid-row-gap: 1rem;
      grid-auto-flow: row;
      justify-items: center;
    }
    a {
      color: ${(props) => props.theme.colors.white_100};
      font-family: "SatoshiRegular";
      margin-right: 1rem;
      font-size: 0.875rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
