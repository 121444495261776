import About from "views/components/pages/Home/About";
import Funding from "views/components/pages/Home/Funding";
import Growth from "views/components/pages/Home/Growth";
import Hero from "views/components/pages/Home/Hero";
import Piece from "views/components/pages/Home/Piece";
import Testimonial from "views/components/pages/Home/Testimonial";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Testimonial />
      <Growth />
      <Piece />
      <Funding />
    </>
  );
};

export default Home;
