import styled from "styled-components";

export const Piecerapper = styled.div`
  padding: 7rem 0;
  background-color: ${(props) => props.theme.colors.purple_500};

  .content_wrapper {
    display: flex;
    gap: 1rem;
    .piece_icon {
      display: flex;
      justify-content: center;
      gap: 1rem;
      align-items: center;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 2rem 0;
    overflow: hidden;
  }
`;
