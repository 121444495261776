import styled from "styled-components";

export const ProcessWrapper = styled.div`
  background: ${(props) => props.theme.colors.purple_200};
  padding: 10rem 0rem;
  .process_wrapper {
    display: flex;
    gap: 1rem;
  }
`;
