import styled from "styled-components";

export const NewsCardWrapper = styled.div`
  padding: 6rem 0;
  .tag {
    background: #f4f4f4;
    color: ${(props) => props.theme.colors.purple_100};
    display: inline-block;
    border-radius: 0.1rem;
    margin-right: 1rem;
    padding: 0.25rem 0.5rem;
    font-family: "SatoshiRegular";
  }
  .card-wrapper {
    background-color: #fff;
    box-shadow: 0 24px 48px -12px rgba(0, 0, 0, 0.04);
    border: none;
    &:hover {
      box-shadow: 0 24px 48px -12px rgba(0, 0, 0, 0.12);
    }
  }
  .link {
    color: ${(props) => props.theme.colors.purple_100};
    text-decoration: none;
    font-family: "SatoshiBold";
  }
  a {
    text-decoration: none;
  }
`;
