import { Container, Row, Col } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import { ChatWrapper } from "./styles";
import { Link } from "react-router-dom";

const Chat = () => {
  return (
    <ChatWrapper>
      <Container style={{ position: "relative", zIndex: 1 }}>
        <Row>
          <Col className="md-8">
            <Heading level={2} className="h4Medium text-center">
              Love small talk about big ideas? We really want to hear from
              you.&nbsp;
              <Link className="chat_link" to="/contact">
                let's chat.
              </Link>
            </Heading>
          </Col>
        </Row>
      </Container>
    </ChatWrapper>
  );
};

export default Chat;
