import React, { useEffect, useState } from "react";
import { Container, Form, Nav, Navbar, Image } from "react-bootstrap";
import { HeaderWrapper } from "./styles";
import Button from "views/components/shared/Button";
import { AppRoutes } from "views/components/constants/routes";
import { useLocation, Link } from "react-router-dom";
import logo from "assets/Logo/logo.svg";
import logoWhite from "assets/Logo/logo-white.svg";

function Header() {
  const [isTransparent, setIsTransparent] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const navbarWhite = [AppRoutes.about, AppRoutes.contact];

  useEffect(() => {
    const handleScroll = () => {
      setIsTransparent(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };

  const isNavbarWhite = navbarWhite.some((path) =>
    location.pathname.startsWith(path)
  );

  const renderNavLinks = () => (
    <>
      <Link to={AppRoutes.about} className="nav-link" onClick={handleLinkClick}>
        About
      </Link>
      <Link to={AppRoutes.news} className="nav-link" onClick={handleLinkClick}>
        News
      </Link>
      <Button
        className="primary"
        to={AppRoutes.contact}
        onClick={handleLinkClick}
      >
        Let’s Connect
      </Button>
      <Button
        className="primary ms-md-4 ms-0 mt-2 mt-md-0"
        to={"https://taskgopro.com"}
        onClick={handleLinkClick}
      >
        Login
      </Button>
    </>
  );

  return (
    <HeaderWrapper>
      <Navbar
        expand="lg"
        expanded={expanded}
        className={`${isTransparent ? "bg-body-tertiary" : ""}`}
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 1000,
          backgroundColor: isTransparent
            ? "transparent"
            : "rgba(255, 255, 255, 1)",
          transition: "background-color 0.3s ease",
        }}
      >
        <Container>
          <Navbar.Brand
            href="/"
            className={isTransparent && isNavbarWhite ? "logo-white" : "logo"}
          >
            <Image
              src={isTransparent && isNavbarWhite ? logoWhite : logo}
              fluid
              className="d-none d-md-inline-block"
            />
            <Image src={logo} fluid className="d-block d-md-none" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={handleToggle} />
          <Navbar.Collapse
            id="navbarScroll"
            className={isTransparent && isNavbarWhite ? "about-link" : ""}
          >
            <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
              {renderNavLinks()}
            </Nav>
            <Form className="d-flex"></Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </HeaderWrapper>
  );
}

export default Header;
