import React from "react";
import Lottie from "lottie-react";
import animationData from "assets/Animations/logoAnimation.json";

const HeroAnimation = () => {
  return (
    <div>
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
};

export default HeroAnimation;
