export const Theme = {
  colors: {
    white_100: "#fff",
    white_200: "#f4f4f4",
    balck_100: "#000",
    balck_200: "#222",
    balck_300: "#1b1c1e",
    aqua_100: "#353635",
    purple_0: "#cb82fd9f",
    purple_100: "#4A93CF",
    purple_200: "#353635",
    purple_300: "#408dcd",
    purple_400: "#151515",
    purple_500: "#4A93CF",
  },

  media: {
    md: 768,
    sm: 500,
    lg: 968,
    xl: 1440,
  },
  utils: {},
};
