import { AppRoutes } from "./views/components/constants/routes";
import { Route, Routes } from "react-router-dom";
import Home from "views/pages/home";
import About from "views/pages/about";
import Contact from "views/pages/contact";
import News from "views/pages/news";
import ScrollToTop from "ScrollToTop";
import Privacy from "views/pages/privacy";
import Cookies from "views/pages/cookies";
import NewsDetail from "views/components/pages/News/DetailNews";
import CookieConsent from "views/components/shared/CookieConsent";
import { useCookies } from "react-cookie";

function App() {
  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <>
      <ScrollToTop />
      {!cookies.cookieConsent && <CookieConsent />}
      <Routes>
        <Route path={AppRoutes.homeSlash} element={<Home />} />
        <Route path={AppRoutes.about} element={<About />} />
        <Route path={AppRoutes.contact} element={<Contact />} />
        <Route path={AppRoutes.news} element={<News />} />
        <Route path={AppRoutes.privacy} element={<Privacy />} />
        <Route path={AppRoutes.cookies} element={<Cookies />} />
        <Route path={AppRoutes.newsDetail} element={<NewsDetail />} />
      </Routes>
    </>
  );
}

export default App;
