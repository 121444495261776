import styled from "styled-components";

export const ParagraphWraper = styled.div`
  .mdMedium {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiRegular";
    font-size: 0.875rem;
  }
  .mdxl {
    color: ${(props) => props.theme.colors.balck_300};
    opacity: 0.7;
    font-family: "SatoshiRegular";
    font-size: 1.125rem;
  }
  .mdl {
    color: ${(props) => props.theme.colors.balck_300};
    font-family: "SatoshiRegular";
    font-weight: 600;
    font-size: 1.25rem;
  }

  .lgXl {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    padding-bottom: 5rem;
    @media screen and (max-width: 600px) {
      padding: 1rem 0rem;
      font-size: 24px;
    }
  }
  .lgLarge {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    font-weight: 700;
    font-size: 28px;
    padding: 5rem;
    @media screen and (max-width: 600px) {
      padding: 1rem;
      font-size: 24px;
    }
  }
  .lgSmall {
    color: ${(props) => props.theme.colors.balck_300};
    font-family: "SatoshiRegular";
    font-weight: 500;
    font-size: 18px;
  }

  .lgXsmall {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiRegular";
    font-weight: 500;
    font-size: 18px;
    margin-left: 2.5rem;
  }

  .mdLarge {
    color: ${(props) => props.theme.colors.purple_100};
    font-family: "SatoshiBold";
    font-weight: 700;
    font-size: 2rem;
  }

  .mdSmall {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiRegular";
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
  }
`;
