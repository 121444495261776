import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Card, Col, Row, Carousel } from "react-bootstrap";
import { TestimonialWrapper } from "./styles";
import CircleIcon from "assets/Icons/CircleIcon";
import { cardData } from "./utils/data";
import Paragraph from "views/components/shared/Paragraph";
import Heading from "views/components/shared/Heading";

const CardSection = () => {
  return (
    <TestimonialWrapper>
      <Container fluid className="my-5">
        <Row className="justify-content-center">
          <Col md={5}>
            <div data-aos="flip-up" data-aos-duration={500}>
              <Heading className="h2l text-center" level={2}>
                Lets go through our solutions<span>.</span>
              </Heading>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={9}>
            <Carousel>
              {cardData.map((card) => (
                <Carousel.Item>
                  <div key={card.id} className="p-3">
                    <Card className="card_wrapper">
                      <Card.Img className="card_img" src={card.imgSrc} />
                      <Card.Body>
                        <div className="pb-4">
                          <CircleIcon />
                        </div>
                        <Paragraph className="mdxl">{card.text}</Paragraph>
                        <Paragraph className="pt-2">{card.title}</Paragraph>
                      </Card.Body>
                    </Card>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </TestimonialWrapper>
  );
};

export default CardSection;
