import { ParagraphWraper } from "./styles";
import ParagraphProps from "./utils/interfaces";

const Paragraph: React.FC<ParagraphProps> = ({
  className,
  children,
  style,
}) => {
  return (
    <ParagraphWraper>
      <p className={className} style={style}>
        {children}{" "}
      </p>
    </ParagraphWraper>
  );
};

export default Paragraph;
