export default function CircleIcon() {
  return (
    <svg width="42" height="42" viewBox="0 0 63.969 63.969">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="63.969"
            height="63.969"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_3" data-name="Group 3" clip-path="url(#clip-path)">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M31.983,63.969V0a31.984,31.984,0,1,0,0,63.969"
          transform="translate(0.001)"
          fill="#4A93CF"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M146.6,31.984a31.984,31.984,0,0,0,31.984,31.984V0A31.984,31.984,0,0,0,146.6,31.984"
          transform="translate(-114.621)"
          fill="#4A93CF"
        />
      </g>
    </svg>
  );
}
