export default function RoundIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2705_17609)">
        <circle cx="19" cy="19" r="7.75" stroke="#ffffff" stroke-width="2.5" />
        <circle cx="19" cy="19" r="17.75" stroke="#ffffff" stroke-width="2.5" />
      </g>
      <defs>
        <clipPath id="clip0_2705_17609">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
