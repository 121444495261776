import { Container } from "react-bootstrap";
import Footer from "views/components/shared/Footer";
import Header from "views/components/shared/Header";
import HeaderProps from "./utils/interfaces";

const CustomLayout: React.FC<HeaderProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Container fluid className="site-layout p-0">
        <div className="content-padding">{children}</div>
      </Container>
      <Footer />
    </>
  );
};

export default CustomLayout;
