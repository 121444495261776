import styled from "styled-components";

export const AboutHeroWrapper = styled.div`
  background: ${(props) => props.theme.colors.purple_100};
  opacity: 0.9;
  padding: 16rem 0rem 8rem;
  @media screen and (max-width: 992px) {
    padding: 5rem;
  }
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`;
