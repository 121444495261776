// src/views/pages/About.js

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import AboutImg from "assets/Images/Aboutus_img.jpg";
import Paragraph from "views/components/shared/Paragraph";
import { AboutUsWrapper } from "./styles";

const About = () => {
  return (
    <AboutUsWrapper>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col md={8} lg={6} className="mt-5 mt-md-0">
            <Paragraph className="lgLarge">
              If you take one thing from this About Us, then understand that
              Taskgo is about YOU. We’re here to share our business experience,
              digital expertise, and software administration to help your
              business grow.
            </Paragraph>
          </Col>
          <Col md={8} lg={6} className="p-0 mt-5 mt-md-0 ">
            <Image src={AboutImg} fluid />
          </Col>
        </Row>
      </Container>
    </AboutUsWrapper>
  );
};

export default About;
