export const accordionData = [
  {
    title: "What is a business consultant? ",
    content:
      "A business consultant is someone who works with management to solve organisational problems, find opportunities for improvements, and implement effective solutions",
  },
  {
    title: "Will you tell me how to run my business? ",
    content:
      "No, that’s not what we do. We bring in an outside view, other experience, ask questions, present solutions, and work with your business on whatever it needs",
  },
  {
    title: "Who do you work with? ",
    content:
      "We only take on clients who are serious about growing their profits. We offer a free 30 minute consultation and try to understand your needs before deciding to bring on a new client. We limit the number of clients we work with at any given moment so that we can maximise our client benefits.",
  },
  {
    title: "What is the Taskgo Pharmacy Management System? ",
    content:
      "We developed an inhouse software to manage the administrative workload within pharmacy. From all legislative logs, managing projects, claims and purchasing analysis, we’ve got you covered. We’ve spent over 2 years piloting this out within pharmacy and have calculated as having saved hundreds of hours per year. Less time spent on essential administration, juggling projects and analysing claims means more time boosting profits",
  },
];

export const Data = [
  {
    title: "Why would I use a business consultant?",
    content:
      "When you have a challenge to solve, when you are looking for ways to grow, when you need to reduce costs or improve productivity and when you need to innovate or deliver significant organisational change",
  },
  {
    title: "Why should I choose to work with taskgo?",
    content:
      "There are so many business consultants but how many actually understand pharmacy. We work on the front line every day and so understand the market needs. It ultimately comes down to needs and trust.",
  },
  {
    title: "I already have a website, how easy is it to change? ",
    content:
      "If you already have a website then that’s great. This means we can develop your new website whilst your other website is still live. Once we are ready, the swap can take as little as 10 minutes",
  },
];
