import styled from "styled-components";

export const HeaderWrapper = styled.header`
  .logo {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    font-size: 2.2rem;
    font-weight: 700;
    span {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }

  .bg-body-tertiary {
    background-color: transparent !important;
    @media (max-width: 780px) {
      background-color: white !important;
    }
  }

  a.nav-link {
    font-family: "SatoshiBold";
    padding: 0.5rem 1rem;
    font-weight: 800;
    color: #222 !important;
    font-size: 1rem;
    margin-right: 3rem;
    &:hover {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }

  .navbar-toggler {
    border: transparent;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .about-link .nav-link {
    color: white !important;
    &:hover {
      color: ${(props) => props.theme.colors.purple_100};
    }
    @media (max-width: 780px) {
      color: #222 !important;
    }
  }

  .logo-white {
    color: white;
    font-family: "SatoshiBold";
    font-size: 2.2rem;
    font-weight: 700;
    @media (max-width: 780px) {
      color: ${(props) => props.theme.colors.purple_200};
      span {
        color: ${(props) => props.theme.colors.purple_100};
      }
    }
  }
  .navbar-nav {
    align-items: center;
    @media (max-width: 780px) {
      align-items: start;
    }
  }
`;
