import styled from "styled-components";

export const DetailedNewsCardWrapper = styled.div`
  padding: 6rem 0;
  .tag {
    background: #f4f4f4;
    color: ${(props) => props.theme.colors.purple_100};
    display: inline-block;
    border-radius: 0.1rem;
    margin-right: 1rem;
    padding: 0.25rem 0.5rem;
    font-family: "SatoshiBold";
  }
  .spiner-wrapper {
    height: 90vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;
