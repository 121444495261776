import { Col, Container, Row } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";

const Privacy = () => {
  return (
    <>
      <Container className="py-5">
        <Row className="py-5">
          <Col md={12}>
            <Heading className="h1 ps-0 mt-5 pt-5">Privacy Policy</Heading>
            <Paragraph className="mdxl mt-4">Updated: 15.06.2023</Paragraph>

            <div>
              <Paragraph className="mdxl mt-4">
                Taskgo Pharma Ltd is committed to safeguarding your privacy.
                Here, we describe what personal data we collect and how that
                data is used. GDPR requires that we provide information
                “relating to processing to the data subject in a concise,
                transparent, intelligible and easily accessible form, using
                clear and plain language” (GDPR Article 12.1).
              </Paragraph>
              <Paragraph className="mdxl mt-4">
                We do not sell your data, and if we are the Data Controller we
                do not let others copy your data for their own purposes.
              </Paragraph>
              <Paragraph className="mdxl mt-4">
                Privacy laws in many countries, e.g. the GDPR EU, give you
                rights over your personal data, including the right to receive a
                copy, or have it deleted (more about Individual Rights).
              </Paragraph>
              <Paragraph className="mdxl mt-4">
                To request any privacy right, if Taskgo Pharma Ltd is the Data
                Controller, please contact us directly via the contact page at
                taskgo.ie. For security reasons, we may sometimes have to ask
                you to prove your identity.
              </Paragraph>

              <Heading level={2} className="privacy">
                Visitors to our website or those making contact with us via
                other channels, such as social media.
              </Heading>
              <Paragraph className="mdxl mt-4">
                Taskgo Pharma is the Data Controller and the lawful basis is
                Legitimate Interest, or Consent if necessary (more). Please
                contact us via the contact page on our website at taskgo.ie, if
                you want more information about your personal data.
              </Paragraph>

              <Heading level={2} className="privacy">
                Main types of Data:
              </Heading>
              <ul>
                <li>
                  Our website uses cookies to identify you and keep track of
                  your browsing sessions (cookies policy).
                </li>
                <li>
                  We keep a record of web pages seen, emails and other contacts.
                </li>
                <li>
                  We make a note when you contact us, e.g. by using our website
                  form or over email.
                </li>
                <li>
                  We may collect public information, such as your position in
                  the organisation.
                </li>
                <li>
                  Our SEO activity may also involve the use of marketing tools
                  as listed below.
                </li>
              </ul>

              <Heading level={2} className="privacy">
                Data is used for:
              </Heading>
              <ul>
                <li>
                  Running this website – for example to track sessions and count
                  how many people visit.
                </li>
                <li>For assessing the quality of potential clients.</li>
                <li>For booking client consultations.</li>
              </ul>

              <Heading level={2} className="privacy">
                We use the following categories of sales and marketing tools:
              </Heading>
              <ul>
                <li>letshost.ie servers to host this website</li>
                <li>
                  Analytics to count page visits etc., including Google
                  Analytics.
                </li>
                <li>Google Ads for marketing</li>
                <li>LinkedIn Ads for marketing</li>
                <li>Emails for B2B marketing, scheduling emails</li>
                <li>Email Service Providers to send emails</li>
                <li>Online document signing tools</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Privacy;
