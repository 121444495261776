import styled from "styled-components";
import prevArrow from "assets/Icons/slider-prev-arrow.svg";
import nextArrow from "assets/Icons/slider-next-arrow.svg";

export const TestimonialWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white_200};
  padding: 5rem 0;
  .h2l {
    span {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }

  .card_wrapper {
    display flex;
    gap: 4rem;
    width: 100%;
    height: 380px;
    flex-direction: row;
    padding: 2rem 8rem 2rem 2rem;
    border-radius: 1.875rem;
    border: transparent;
    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 1rem;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      height: auto;
    }
  }
  .card_img {
    width: 273px;
    height: 316px;
    border-radius: 10px;
    object-fit: cover;
  }
  .carousel-control-prev-icon{
    position: absolute;
    left: 0;
    background-image: url(${prevArrow});
  }
  .carousel-control-next-icon{
    position: absolute;
    right: 0;
    background-image: url(${nextArrow}); 
  }
  .carousel-control-next, .carousel-control-prev{
    opacity: 1;
  }

  @media screen and (max-width: 700px) {
    .card_wrapper {
      overflow: hidden ;
    }
  }
  .slick-slide{
    width:992px !important;
    @media (max-width: 780px) {
      width:387px !important;
    }
  }
`;
