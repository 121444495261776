import ContactHero from "views/components/pages/Contact/ContactHero";

const Contact = () => {
  return (
    <>
      <ContactHero />
    </>
  );
};

export default Contact;
