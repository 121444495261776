import React, { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Data, accordionData } from "./utils";
import FullCircleIcon from "assets/Icons/FullCircleIcon";
import { FaqWrapper, RotatingIconWrapper } from "./styles";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";

const Faq: React.FC = () => {
  const [activeKeysCol1, setActiveKeysCol1] = useState<number[]>([]);
  const [activeKeysCol2, setActiveKeysCol2] = useState<number[]>([]);

  const handleAccordionClickCol1 = (index: number) => {
    setActiveKeysCol1((prevKeys) =>
      prevKeys.includes(index)
        ? prevKeys.filter((key) => key !== index)
        : [...prevKeys, index]
    );
  };

  const handleAccordionClickCol2 = (index: number) => {
    setActiveKeysCol2((prevKeys) =>
      prevKeys.includes(index)
        ? prevKeys.filter((key) => key !== index)
        : [...prevKeys, index]
    );
  };

  return (
    <FaqWrapper>
      <Container>
        <Row>
          <Heading className="h5XXl pb-5" level={2}>
            FAQs
          </Heading>
          <Col md={6}>
            <Accordion>
              {accordionData.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  key={index}
                  onClick={() => handleAccordionClickCol1(index)}
                >
                  <Accordion.Header>
                    <RotatingIconWrapper
                      rotated={activeKeysCol1.includes(index)}
                    >
                      <FullCircleIcon />
                    </RotatingIconWrapper>
                    <Heading level={4} className="h4Small">
                      {item.title}
                    </Heading>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Paragraph className="lgXsmall border-bottom">
                      {item.content}
                    </Paragraph>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
          <Col md={6}>
            <Accordion>
              {Data.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  key={index}
                  onClick={() => handleAccordionClickCol2(index)}
                >
                  <Accordion.Header>
                    <RotatingIconWrapper
                      rotated={activeKeysCol2.includes(index)}
                    >
                      <FullCircleIcon />
                    </RotatingIconWrapper>
                    <Heading level={4} className="h4Small">
                      {item.title}
                    </Heading>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Paragraph className="lgXsmall border-bottom">
                      {item.content}
                    </Paragraph>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </FaqWrapper>
  );
};

export default Faq;
