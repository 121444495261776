import { createGlobalStyle } from "styled-components";

import SatoshiRegular from "assets/Fonts/Satoshi-Regular.woff";
import SatoshiBold from "assets/Fonts/Satoshi-Bold.woff";

export const FontStyles = createGlobalStyle`

  @font-face {
    font-family: 'SatoshiRegular';
    src:  url(${SatoshiRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'SatoshiBold';
    src:  url(${SatoshiBold}) format('woff');
    font-style: normal;
    font-weight: 700;

  }
  li{
      font-family: 'SatoshiRegular';
  }
  .spiner-wrapper {
    height: 90vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;
