import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Theme } from "./globals/Theme";
import CustomLayout from "./views/components/Layout/Main";
import { FontStyles } from "globals/global";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <FontStyles />
      <BrowserRouter>
        <CustomLayout>
          <App />
        </CustomLayout>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
