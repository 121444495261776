import { fetchEntries } from "contentFullApi";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Post } from "../utils/types";
import defaultImg from "assets/Images/testimonial_img1.jpg";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { DetailedNewsCardWrapper } from "./styles";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";

const NewsDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getPost = async () => {
      try {
        const entries: Post[] = await fetchEntries("taskgoNews");
        const post = entries.find((entry) => entry.fields.slug === slug);
        setPost(post || null);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setLoading(false);
      }
    };

    getPost();
  }, [slug]);

  if (!post) {
    return (
      <div className="spiner-wrapper">
        <Spinner animation="grow" />
      </div>
    );
  }

  const { title, description, tag, featureImageUrl } = post.fields;
  const imageUrl = featureImageUrl || defaultImg;

  return (
    <DetailedNewsCardWrapper>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            {loading ? (
              <div className="spiner-wrapper">
                <Spinner animation="grow" />
              </div>
            ) : (
              <>
                <Image src={imageUrl} alt={title} fluid className="w-100" />
                <div className="tag  mt-4 mb-4">{tag}</div>
                <Heading level={2} className="detail-news-title mb-4">
                  {title}
                </Heading>
                {description.content.map((content, index) => (
                  <Paragraph className="mdxl">
                    {content.content[0].value}
                  </Paragraph>
                ))}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </DetailedNewsCardWrapper>
  );
};

export default NewsDetail;
