export default function ArrowIcon() {
  return (
    <svg
      aria-hidden="true"
      role="img"
      class="iconify iconify--ic"
      width="32"
      height="32"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="m12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
      ></path>
    </svg>
  );
}
