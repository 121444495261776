import styled from "styled-components";

export const HeroWrapper = styled.div`
  .h1 {
    span {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }
  .carousel-indicators {
    display: none;
  }
  @keyframes slideOutRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-65%);
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes slideOutRightWithFade {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      transform: translateX(-0%);
      opacity: 0;
    }
  }
  .logo-bg {
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 105vh;
  }

  .slide-out-right {
    animation: slideOutRight 2s forwards;
  }
  .slide-out-right-with-fade {
    animation: slideOutRightWithFade 1s forwards;
  }
  .arrow-animation {
    position: absolute;
    right: 48.3%;
    bottom: 5%;
    z-index: 1;
    width: 3.5%;
    @media (max-width: 780px) {
      width: 10.5%;
    }
  }
  .hero-text {
    padding-right: 130px;
    @media (max-width: 780px) {
      padding-right: 0px;
    }
  }
  .hero-wrapper {
    align-items: center;
    height: 105vh;
    overflow: hidden;
  }
  .mobile-animation {
    display: flex;
    align-items: center;
    height: 105vh;
  }
  .slider-img {
    height: 105vh;
    object-fit: cover;
    @media (max-width: 780px) {
      height: 52.5vh;
    }
  }
  .responsive-img-wrapper {
    @media (max-width: 780px) {
      display: flex;
      height: 52.5vh;
      align-items: center;
    }
  }
`;
