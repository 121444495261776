export default function SemiCircle() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0658 40.2598C48.1645 26.1637 43.3591 8.13976 29.3301 0L0 51.0469C14.029 59.1844 31.967 54.356 40.0658 40.2598Z"
        fill="black"
      ></path>
    </svg>
  );
}
