import { Col, Container, Row } from "react-bootstrap";
import Heading from "views/components/shared/Heading";
import Paragraph from "views/components/shared/Paragraph";

const Cookies = () => {
  return (
    <>
      <Container className="py-5">
        <Row className="py-5">
          <Col md={12}>
            <Heading className="h1 ps-0 mt-5 pt-5">Cookies Settings</Heading>

            <div>
              <Paragraph className="mdxl mt-4">Updated: 15.06.2024</Paragraph>

              <Paragraph className="mdxl mt-4">
                This is our marketing site. By visiting or remaining you consent
                to experience our marketing. We need good marketing to
                effectively communicate the benefits of our products and
                services, which requires cookies as an integral part, so we use
                these as a GDPR legitimate interest.
              </Paragraph>

              <Heading level={2} className="privacy">
                (1) How we use cookies
              </Heading>
              <Paragraph className="mdxl mt-4">
                The information from cookies may be used to offer assistance, or
                to contact you in relation to the products and pages viewed, or
                to help us improve the Website. We will not sell the information
                to third parties.
              </Paragraph>

              <Heading level={2} className="privacy">
                (2) Third party cookies
              </Heading>
              <Paragraph className="mdxl mt-4">
                When you use our website, you may also be sent third party
                cookies by the following marketing tools:
              </Paragraph>
              <ul>
                <li>
                  Google Analytics to analyse overall Website usage. It sets a
                  cookie to track visits. The privacy policy is available at:{" "}
                  <a
                    href="http://www.google.co.uk/intl/en/policies/privacy/"
                    target="_blank"
                  >
                    http://www.google.co.uk/intl/en/policies/privacy/
                  </a>
                </li>
                <li>
                  MailChimp for B2B marketing and scheduling emails. The privacy
                  policy is available at:{" "}
                  <a
                    href="https://www.intuit.com/privacy/statement/"
                    target="_blank"
                  >
                    https://www.intuit.com/privacy/statement/
                  </a>
                </li>
                <li>
                  Embedded content, such as videos, may use cookies. For example
                  the youtube privacy policy is available at:{" "}
                  <a
                    href="https://policies.google.com/privacy?hl=en"
                    target="_blank"
                  >
                    https://policies.google.com/privacy?hl=en
                  </a>
                </li>
              </ul>

              <Heading level={2} className="privacy">
                (3) Cookie preferences
              </Heading>
              <Paragraph className="mdxl mt-4">
                To manage your cookie preferences, select your browser from the
                list below and follow the instructions. NB: if you block
                cookies, this will reduce the usability of some websites:
              </Paragraph>
              <ul>
                <li>Chrome</li>
                <li>Firefox</li>
                <li>Internet Explorer</li>
                <li>Opera</li>
                <li>Safari</li>
              </ul>
              <Paragraph className="mdxl mt-4">Mobile devices:</Paragraph>
              <ul>
                <li>Android</li>
                <li>Blackberry</li>
                <li>iPhone/iPad Safari</li>
                <li>Windows Phone</li>
              </ul>

              <Heading level={2} className="privacy">
                (4) Contact us
              </Heading>
              <Paragraph className="mdxl mt-4">
                This website is owned and operated by Taskgo Pharma Ltd. You can
                contact us through the contact page at Taskgo’s website.
              </Paragraph>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Cookies;
